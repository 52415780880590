<template>
  <section class="adminBar" data-testid="admin-bar">
    <div class="adminBar__actions" v-if="getCategoryViewMode">
      <ButtonPlay type="gray" @click="rearrangeCouses">
        <IconSax name="edit" size="1rem" />
        <span class="adminBar__actions--text">
          {{ getRearrangeCourse ?  $t('my_courses.txt1') :  $t('my_courses.txt2') }}
        </span>
      </ButtonPlay>
    </div>
    <div class="adminBar__actions">
      <ButtonPlay type="gray" @click="openUrlAction('/banners')">
        <IconSax name="edit" size="1rem" />
        <span class="adminBar__actions--text">{{ $t('my_courses.txt3')}}</span>
      </ButtonPlay>
    </div>
    <!-- <div class="adminBar__actions">
      <ButtonPlay type="gray" @click="openUrlAction('/cards')">
        <IconSax name="edit" />
        <span class="adminBar__actions--text">Gerenciar cards</span>
      </ButtonPlay>
    </div> -->
    <div class="adminBar__actions">
      <ButtonPlay type="normal" class="animationPulse" @click="openUrlAction('/config_curso')">
        <IconSax name="video-play" size="1rem" />
        <span class="adminBar__actions--text">{{ $t('my_courses.txt4')}}</span>
      </ButtonPlay>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      getRearrangeCourse: "home/getRearrangeCourse",
      getCategoryViewMode: "config/getCategoryViewMode",
    }),
  },
  methods: {
    ...mapActions({
      actionToggleRearrangeCourse: "home/actionToggleRearrangeCourse",
    }),
    rearrangeCouses() {
      this.actionToggleRearrangeCourse()
    },
    openUrlAction(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.animationPulse {
   animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1); 
}
.adminBar {
  @include flex-row;
  justify-content: flex-end;
  width: 100%;
  gap: var(--spacing-16);

  padding: 16px;

  @media screen and (min-width: 769px) {
    padding: var(--spacing-16) var(--spacing-36);
  }

  &__actions {
    ::v-deep svg {
      width: 16px;
      height: 16px;
    }
    &--text {
      @extend .body-semibold-14;
    }
  }
}
</style>
